import React, { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import "../../Query_self/cards";
import '../../Styles/Styles.css'
import Specialties from './MainPageItem/Specialties';
import Portfolio from './MainPageItem/Portfolio';
import Footer from './MainPageItem/Footer';
import About from './MainPageItem/About';
import Header from './MainPageItem/Header';
import Contact from './MainPageItem/Contact';
import NavBar from './MainPageItem/NavBar';
import { MyContext } from '../../ContexApi/MyContext';
import i18next, { changeLanguage } from 'i18next';

function MainPage() {

    const { themeSite , setThemeSite } = useContext(MyContext)
    const { langSite , setLangSite } = useContext(MyContext)
    const [showMenu, setShowMenu] = useState(true)
    const { i18n, t } = useTranslation();



    useEffect(() => {
        i18n.changeLanguage(langSite)
    }, []);

/////////
    const activeTheme = useRef();

    const menuHandler = () => {
        setShowMenu(!showMenu)

        if (showMenu) {
            document.getElementById("nav-toggle").classList.add('is-active');
            document.getElementById('nav-main-bar').classList.add('show');
        }
        else {
            document.getElementById("nav-toggle").classList.remove('is-active');
            document.getElementById('nav-main-bar').classList.remove('show');
        }
    }
////////////

    const clicked_iran = () => {
        document.getElementById("home").classList.add('rastchin-theme');
        setLangSite('fa')
        i18n.changeLanguage('fa')
    }
    const clicked_america = () => {
        document.getElementById("home").classList.remove('rastchin-theme');
        setLangSite('en')
        i18n.changeLanguage('en')
    }


    const lightTheme = () => {
        document.getElementById("body").classList.add('dark');
        setThemeSite('dark')

    }
    const darkTheme = () => {
        document.getElementById("body").classList.remove('dark');
        setThemeSite('light')
    }



    return (

        <>


            <div data-spy="scroll" data-target=".navbar" data-offset={40} id="home" className=''>
                
                <NavBar menuHandler={menuHandler} lightTheme={lightTheme} darkTheme={darkTheme} 
                    clicked_iran={clicked_iran} clicked_america={clicked_america}
                />

                <Header />

                <About />

                <Specialties />

                <Portfolio />


                <section className="section-sm bg-primary">
                    {/* container */}
                    <div className="container text-center text-sm-left">
                        {/* row */}
                        <div className="row align-items-center">
                            <div className="col-sm offset-md-1 mb-4 mb-md-0 hire-me">
                                <h6 className="title text-light">{t('wantTo')}</h6>
                                <p className="m-0 text-light">{t('wantTo_description')}</p>
                            </div>
                            <div className="col-sm offset-sm-2 offset-md-3">
                                <button className="btn btn-lg my-font btn-light rounded">{t('hireMe')}</button>
                            </div>
                        </div> {/* end of row */}
                    </div> {/* end of container */}
                </section>

                {/* <Blog /> */}

                <Contact />

                <Footer />

            </div>










        </>






    )
}

export default MainPage








