import React, { useEffect } from 'react'
import Secondary from '../Secondary'
import image1 from '../../../Images/Images/resume/res.jpg'
import { useTranslation, Trans } from 'react-i18next';

function AboutSec() {
  const { i18n, t } = useTranslation();
  return (
    <>
      <Secondary title={t('myresume')}>
        <div className='main-resume-secpage' >
          <div className='resume-image-div' >
            <img src={image1} className='resume-image' alt='' />
            {/* <img src={image2} className='resume-image' alt='' /> */}
          </div>
          {/* <div className='div-resume-ask' >
            <a href='../../pdf/res.pdf' download='resume.pdf' >
              <h4 className='resume-ask' >{t('download_cv')}</h4>
            </a>
          </div> */}
        </div>
      </Secondary>
    </>
  )
}
export default AboutSec