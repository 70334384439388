import React from 'react';
import { BsArrowLeftShort, BsArrowRightShort } from "react-icons/bs";
import Secondary from '../Secondary';
function BackEnd() {
  return (
    <>
      <Secondary>
        <div className='main-secondary-project'>

          

        </div>
      </Secondary>



    </>




  )
}

export default BackEnd