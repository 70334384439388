
import './App.css';
import BlogSec from './Components/SecondaryPage/Blog/Blog';
import AboutSec from './Components/SecondaryPage/About';
import Secondary from './Components/SecondaryPage/Secondary';
import MainPage from '../src/Components/MainPage/MainPage'
import { Route, Routes } from 'react-router';
import FrontEnd from './Components/SecondaryPage/Projects/FrontEnd';
import { MyContext } from './ContexApi/MyContext';
import { useEffect, useState } from 'react';
import BackEnd from './Components/SecondaryPage/Projects/BackEnd';

function App() {

  const [themeSite, setThemeSite] = useState('light');
  const [langSite, setLangSite] = useState('en');

  useEffect(() => {
    if (themeSite === 'light') {
      console.log(true)
    }
    if (themeSite === 'dark') {
      console.log(false)
    }

  }, [])

  return (
    <MyContext.Provider value={{ themeSite, setThemeSite, langSite, setLangSite }}>

      <div className="">
        <Routes>
          <Route path='/' element={<MainPage />} />
          <Route path='/resume' element={<AboutSec />} />
          <Route path='/front' element={<FrontEnd />} />
          <Route path='/back' element={<BackEnd />} />
        </Routes>
      </div>
    </MyContext.Provider>
  );
}

export default App;
