import React, { useContext, useEffect, useState } from 'react'
import NavSecondary from './SecondaryNav/Nav_sec'
import Footer from '../MainPage/MainPageItem/Footer';
import './SecondaryNav/styleSecondary.css';
import { BsSearch } from "react-icons/bs";
import { useTranslation, Trans } from 'react-i18next';
import { Link } from 'react-router-dom';
import { MyContext } from '../../ContexApi/MyContext';



function Secondary(props) {

    const { themeSite , setThemeSite } = useContext(MyContext)

    useEffect(() => {
        console.log(themeSite)
    }, []);

    const { i18n, t } = useTranslation();




    return (
        <>
            <NavSecondary />
            <div className='secondaries'>
                <div className='row'>
                    <div className='col-12 col-lg-3' ></div>
                    <h2 className='secondary-content-head col-12 col-lg-9 customize-h2-secondary'>{props.title}</h2>
                </div>
                <section className='d-flex p-2 secondary-main-section'>
                    <section className='col-12 col-lg-3 p-3'>
                        <div className='secondary-sidebar'>
                            <div className='secondary-sidebar-searchbox'>
                                <input className='secondary-search-input'></input>
                                <div className='secondary-search-icon'>
                                    <BsSearch size={20} />
                                </div>
                            </div>
                            <div className='secondary-sidebar-list'>
                                <ul className='list-main-sidebar'>
                                    <Link to='/' ><li className='list-item-sidebar'>{t('Home')}</li></Link>
                                    <Link to='/resume' ><li className='list-item-sidebar'>{t('myresume')}</li></Link>
                                    <Link to='/front' ><li className='list-item-sidebar'>{t('Projects')}</li></Link>
                                    {/* <Link to='/#projects' ><li className='list-item-sidebar'>{t('Projects')}</li></Link> */}
                                </ul>
                            </div>
                        </div>
                    </section>
                    <section className='col-12 col-lg-9 py-2 px-4'>
                        <div className='secondary-content-main'>
                            <div className='secondary-content-body'>
                                {props.children}
                            </div>
                        </div>
                    </section>
                </section>
            </div>
            <Footer />
        </>
    )
}

export default Secondary