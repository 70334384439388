import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
    // detect user language
    // learn more: https://github.com/i18next/i18next-browser-languageDetector
    .use(LanguageDetector)
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
        debug: true,
        fallbackLng: 'en',
        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        },
        resources: {
            en: {
                translation: {
                    Home: 'Home',
                    About: 'About',
                    Contact: 'Contact',
                    Blog: 'Blog',
                    Amirreza: 'AmirReza',
                    Hi : 'Hi !',
                    my_name: 'I am Amirreza Khesali',
                    developer: 'FRONT END DEVELOER',
                    knowing : 'Do You Like Know Who Am I ?' ,
                    about_me: 'A brief look About Me',
                    Languages: 'Languages',
                    Projects: 'Projects',
                    Specialties : "Specialties" ,
                    What_Project_I_Do: 'What Project I Do ?',
                    short_see : 'Hello, I am Amirrezam and I am 26 years old.' ,
                    short_see2 : 'I always try to be responsible and put as much energy into work as I can.' ,
                    short_see3 : 'I like team work very much and I try to be useful and efficient for the team and my main goal is to improve as much as possible. I have a lot of motivation to learn and improve.' ,
                    download_cv: "Download CV",
                    html_css : 'Html & Css' ,
                    JavaScript : 'JavaScript' ,
                    react : "React JS" ,
                    native : 'React Native',
                    Recent_Post : 'Recent Posts' ,
                    /////// want to work
                    wantTo : 'Want to work with me?' ,
                    wantTo_description : 'Always feel Free to Contact &amp; Hire me' ,
                    hireMe : 'Hire Me' ,
                    /////// want to work
                    /////// comment
                    yourName : "Your Name" ,
                    email : "Email" , 
                    write : "Tell Me Your Comment", 
                    submit : "Submit" ,
                    comment : "What do you think about my site?" ,
                    tell_me : "Share With Me",
                    //////
                    myresume : 'MyResume' ,

                    ///// langs
                    htmlH1 : 'HTML' ,
                    htmldes : 'The first language that I started is HTML5 .' ,
                    cssH1 : 'CSS' ,
                    cssdes : 'In CSS, I tried to learn different things. Among others, I can mention the Transforms, Animation, Flexbox.' ,
                    javaH1 : 'JAVA SCRIPT' ,
                    javades : 'The most important principle for starting front-end programming is JavaScript. I learned this language functional component.' ,
                    queryH1 : 'JQUERY' ,
                    querydes : 'For beginning the JavaScript, In first I practicing some Jquery projects, too.' ,
                    reactH1 : 'REACT JS' ,
                    reactdes : 'All the previous cases were obstacles to reach this attractive and popular JavaScript animation.' ,
                    nodeH1 : 'NODE JS' ,
                    nodedes : 'This is a best language of java script for backEnd programming. so I begin that.' ,
                    expH1 : 'EXPRESS JS' ,
                    expdes : 'Best and popular framework of node js is. this framework so helpfull.' ,
                    restH1 : 'RESTfull API' ,
                    restdes : 'The way that can connect backEnd and frontEnd' ,
                    mongoH1 : 'MONGO DB' ,
                    mongodes : 'Mongo database is one DBMS that let you edit and save the information easyly ' ,
                    ///// langs


                    ///////////////

                    secondary_project_more : 'see project  ->' ,


                    //////////////
                    
                }
            },
            fa: {
                translation: {
                    Home: 'خانه',
                    About: 'درباره من',
                    Contact: 'تماس',
                    Blog: 'بلاگ',
                    Amirreza: 'امیررضا',
                    Hi: 'سلام',
                    my_name: 'به سایت من خوش آمدید',
                    developer: 'توسعه دهنده فرانت اند',
                    knowing : 'دوس داری منو بیشتر بشناسی؟' ,
                    about_me: 'نگاهی کوتاه برای شناخت من',
                    Languages: 'زبان های برنامه نویسی',
                    Projects: 'پروژه ها',
                    What_Project_I_Do: 'نمونه پروژه هام',
                    Specialties : 'تخصص ها' ,
                    short_see : 'سلام، من امیررضام و 26 سالمه.' ,
                    short_see2 : 'همیشه سعی میکنم مسئولیت پذیر باشم و تا جایی که در توانم باشه برای کار انرژی میذارم.' ,
                    short_see3 : ' کار تیمی رو خیلی دوست دارم و تلاش میکنم برای تیم مفید و کارآمد باشم و هدف اصلی من پیشرفت هرچه بیشتر است. انگیزه زیادی هم برای یادگیری و پیشرفت دارم.' ,
                    download_cv: "دریافت فایل رزومه",
                    html_css : 'اچ تی ام ال و سی اس اس' ,
                    JavaScript : 'جاوا اسکریپت' ,
                    react : "ری اکت جی اس" ,
                    native : 'ری اکت نیتیو',

                    Recent_Post : 'مقالات اخیر' ,
                    
                    /////// want to work
                    wantTo : 'آیا تمایل به همکاری با بنده را دارید ؟' ,
                    wantTo_description : 'هر لحظه که بخواهید میتوانید برای همکاری با من تماس بگیرید.' ,
                    hireMe : 'درخواست همکاری' ,
                    /////// want to work

                    myresume : 'رزومه من' ,

                    /////// comment
                    yourName : "اسم" ,
                    email : "نشانی اینترنتی" , 
                    write : "نظرتو بنویس ", 
                    submit : "ثبت نظر" ,
                    comment : "نظرت درباره سایت چیه" ,
                    tell_me : "با من در میون بزار" ,
                    //////
                        ///////////////

                        secondary_project_more : 'مشاهده پروژه  ->' ,


                        //////////////

                    ///// langs
                    htmlH1 : 'HTML' ,
                    htmldes : 'همه چیز با شروه این زبان شروع شد و باعث قدم گذاشتن من در فرانت شد.' ,
                    cssH1 : 'CSS' ,
                    cssdes : 'در سی اس اس من تلاشم بر این بوده که با تمام موارد لازم آشنایی کافی پیدا کنم.' ,
                    javaH1 : 'JAVA SCRIPT' ,
                    javades : 'مهم ترین و البته اصلی ترین قسمت برنامه نویسی وب را می توان به اطمینان جاوا اسکریپت را نام برد.' ,
                    queryH1 : 'JQUERY' ,
                    querydes : 'قبل از شروع جاواسکریپت من چندین پروژه کوچک نیز ر پایه جی کوئری انجام دادم.' ,
                    reactH1 : 'REACT JS' ,
                    reactdes : 'تمام موارد قبلی موانعی برای رسیدن ب این فیمورک جذاب و پرطرفدار جاوا اسکریپت بودند.' ,
                    nodeH1 : 'NODE JS' ,
                    nodedes : 'این زبان نیز برای شروع به دنیای بک اند با استفاده از جاوااسکریپت امری مهم است.' ,
                    expH1 : 'EXPRESS JS' ,
                    expdes : 'محبوب ترین فریمورک زبان نود جی اس، اکسپرس است که بسیار در نوشتن کد های بک اند کمک می کند.' ,
                    restH1 : 'RESTfull API' ,
                    restdes : 'رست نیز چه در فرانت و چه در بک اند باایستی یاد گرفته شود و فهمی کلی از آن داشت.' ,
                    mongoH1 : 'MONGO DB' ,
                    mongodes : 'هر برنامه در آخر نیاز به یک سرور و رنامه مدیریت پایگاه داده ددارد که این را برای من مونگو میسر کرده است.' ,
                    ///// langs
                }
            }
        }
    });

export default i18n;