import React, { useEffect, useState } from 'react';
import { BsArrowLeftShort, BsArrowRightShort } from "react-icons/bs";
import Secondary from '../Secondary';
import gift1 from '../../../Images/Images/gif/applications.gif'
import gift2 from '../../../Images/Images/gif/list.gif'
import gift3 from '../../../Images/Images/gif/startup.gif'
import gift4 from '../../../Images/Images/gif/technician.gif'
import gift5 from '../../../Images/Images/gif/code.png'
import { useTranslation, Trans } from 'react-i18next';

function FrontEnd() {

  const { i18n, t } = useTranslation();

  const projectItem = ([
    {
      image: gift1,
      title: 'Dashboard',
      source: 'React',
      link: 'https://dashboard.amirrezakhs.ir/'
    },
    {
      image: gift2,
      title: 'Survey',
      source: 'React',
      link: 'http://survey.amirrezakhs.ir/'
    },
    {
      image: gift3,
      title: 'Private WebSite',
      source: 'React',
      link: 'https://amirrezakhs.ir/'
    },
    {
      image: gift4,
      title: 'PWA Project',
      source: 'React',
      link: 'https://pwa.amirrezakhs.ir/'
    },
    {
      image: gift5,
      title: 'Mini Projects',
      source: 'github',
      link: 'https://github.com/AmirrezaKhesali'
    },
  ])

  useEffect(() => {
    console.log(projectItem)
  }, [])

  return (
    <>
      <Secondary title = {t('Projects')}>

        <div className='main-secondary-project'>

          {
            projectItem.map((value, index) =>
              <div className='card card-projects-div' key={index}>
                <span className='m-auto p-2 span-project-secondary1' >
                  <img src={value.image} className='projects-secondary-images' />
                </span>
                <span className='m-auto p-2 span-project-secondary2' >{value.source}</span>
                <span className='m-auto p-2 span-project-secondary2' >{value.title}</span>
                <span className='m-auto p-2 span-project-secondary3' >
                  <a href={value.link} target='blank'>
                <div className='see-more-projects' >
                {t('secondary_project_more')}
                  </div>
                </a>
              </span>
              </div>

        )
          }

      </div>
    </Secondary >



    </>




  )
}

export default FrontEnd