import React from 'react'
import { useTranslation } from 'react-i18next';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import '../../../Styles/Styles.css'
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';


function Specialties() {

    const { i18n, t } = useTranslation()


    return (
        <>
            {/* service section */}
            <section className="section" id="Specialties">
                <div className="container text-center">
                    {/* <p className="section-subtitle">{t('What_Project_I_Do')}</p> */}
                    <h6 className="section-title mb-6">{t('Specialties')}</h6>
                    {/* row */}
                    <div className="row">
                        <Swiper
                            spaceBetween={10}

                            modules={[Navigation, Pagination, Scrollbar, A11y]}
                            // navigation
                            pagination={{
                                dynamicBullets: true,
                                clickable: true
                            }}
                            slidesPerView={2}
                            breakpoints={{
                                750: {
                                    slidesPerView: 3,
                                },
                                980: {
                                    slidesPerView: 4,
                                },

                            }}
                            // pagination={{ clickable: true }}
                            scrollbar={{
                                draggable: true,
                                dragSize: 175,
                            }

                            }
                            onSlideChange={() => console.log('slide change')}
                            onSwiper={(swiper) => console.log(swiper)}
                        >
                            <SwiperSlide>
                                <div className="swiper-card">
                                    <div className='flip-card'>
                                        <div className='flip-card-inner'>
                                            <div className='flip-card-front'>
                                                <img src="assets/imgs/html-5.png" className='' />
                                            </div>
                                            <div className='flip-card-back'>
                                            
                                                <h6 className='title card-customize-title narm-opac'>{t('htmlH1')}</h6>
                                                <div className='khate-poshte-card narm-opac'></div>
                                                <p className='text-poshte-card narm-opac'>
                                                {t('htmldes')}

                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="swiper-card">
                                    <div className='flip-card'>
                                        <div className='flip-card-inner'>
                                            <div className='flip-card-front'>
                                                <img src="assets/imgs/css.png" className='' />
                                            </div>
                                            <div className='flip-card-back'>
                                                <h6 className='title card-customize-title narm-opac'>{t('cssH1')}</h6>
                                                <div className='khate-poshte-card narm-opac'></div>
                                                <p className='text-poshte-card narm-opac'>
                                                {t('cssdes')}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="swiper-card">
                                    <div className='flip-card'>
                                        <div className='flip-card-inner'>
                                            <div className='flip-card-front'>
                                                <img src="assets/imgs/social.png" className='' />
                                            </div>
                                            <div className='flip-card-back'>
                                                <h6 className='title card-customize-title narm-opac'>{t('queryH1')}</h6>
                                                <div className='khate-poshte-card narm-opac'></div>
                                                <p className='text-poshte-card narm-opac'>
                                                {t('querydes')}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="swiper-card">
                                    <div className='flip-card'>
                                        <div className='flip-card-inner'>
                                            <div className='flip-card-front'>
                                                <img src="assets/imgs/js.png" className='' />
                                            </div>
                                            <div className='flip-card-back'>
                                                <h6 className='title card-customize-title narm-opac'>{t('javaH1')}</h6>
                                                <div className='khate-poshte-card narm-opac'></div>
                                                <p className='text-poshte-card narm-opac'>
                                                {t('javades')}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="swiper-card">
                                    <div className='flip-card'>
                                        <div className='flip-card-inner'>
                                            <div className='flip-card-front'>
                                                <img src="assets/imgs/react.png" className='' />
                                            </div>
                                            <div className='flip-card-back'>
                                                <h6 className='title card-customize-title narm-opac'>{t('reactH1')}</h6>
                                                <div className='khate-poshte-card narm-opac'></div>
                                                <p className='text-poshte-card narm-opac'>
                                                {t('reactdes')}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="swiper-card">
                                    <div className='flip-card'>
                                        <div className='flip-card-inner'>
                                            <div className='flip-card-front'>
                                                <img src="assets/imgs/node.png" className='' />
                                            </div>
                                            <div className='flip-card-back'>
                                                <h6 className='title card-customize-title narm-opac'>{t('nodeH1')}</h6>
                                                <div className='khate-poshte-card narm-opac'></div>
                                                <p className='text-poshte-card narm-opac'>
                                                    {t('nodedes')}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="swiper-card">
                                    <div className='flip-card'>
                                        <div className='flip-card-inner'>
                                            <div className='flip-card-front'>
                                                <img src="assets/imgs/express.png" className='' />
                                            </div>
                                            <div className='flip-card-back'>
                                                <h6 className='title card-customize-title narm-opac'>{t('expH1')}</h6>
                                                <div className='khate-poshte-card narm-opac'></div>
                                                <p className='text-poshte-card narm-opac'>
                                                    {t('expdes')}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="swiper-card">
                                    <div className='flip-card'>
                                        <div className='flip-card-inner'>
                                            <div className='flip-card-front'>
                                                <img src="assets/imgs/api.png" className='' />
                                            </div>
                                            <div className='flip-card-back'>
                                                <h6 className='title card-customize-title narm-opac'>{t('restH1')}</h6>
                                                <div className='khate-poshte-card narm-opac'></div>
                                                <p className='text-poshte-card narm-opac'>
                                                    {t('restdes')}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="swiper-card">
                                    <div className='flip-card'>
                                        <div className='flip-card-inner'>
                                            <div className='flip-card-front'>
                                                <img src="assets/imgs/mongo.png" className='' />
                                            </div>
                                            <div className='flip-card-back'>
                                                <h6 className='title card-customize-title narm-opac'>{t('mongoH1')}</h6>
                                                <div className='khate-poshte-card narm-opac'></div>
                                                <p className='text-poshte-card narm-opac'>
                                                    {t('mongodes')}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </SwiperSlide>
                        </Swiper>




                    </div>




                    {/* end of row */}
                </div>
            </section > {/* end of service section */}
        </>
    )
}

export default Specialties