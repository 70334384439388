import React from 'react' ;
import { useTranslation, Trans } from 'react-i18next';



function Contact() {


    const { i18n, t } = useTranslation();


    return (
        <>
            {/* contact section */}
            <section className="section" id="contact">
                <div className="container text-center">
                    <p className="section-subtitle">{t('comment')}</p>
                    <h6 className="section-title mb-5">{t('tell_me')}</h6>
                    {/* contact form */}
                    <form action className="contact-form col-md-10 col-lg-8 m-auto">
                        <div className="form-row">
                            <div className="form-group col-sm-6">
                                <input type="text" size={50} className="form-control" placeholder={t('yourName')} required />
                            </div>
                            <div className="form-group col-sm-6">
                                <input type="email" className="form-control" placeholder={t('email')} requried />
                            </div>
                            <div className="form-group col-sm-12">
                                <textarea name="comment" id="comment" rows={6} className="form-control" placeholder={t('write')} defaultValue={""} />
                            </div>
                            <div className="form-group col-sm-12 mt-3">
                                <input type="submit" value={t('submit')} className="btn btn-outline-primary rounded" />
                            </div>
                        </div>
                    </form>{/* end of contact form */}
                </div>{/* end of container */}
            </section>{/* end of contact section */}
        </>
    )
}

export default Contact