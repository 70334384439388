import React from 'react';
import { useTranslation } from 'react-i18next';

function Header() {

  const { i18n, t } = useTranslation();
  
  return (
    <>
        {/* page header */}
        <header id="home" className="header">
                    <div className="overlay" />
                    <div className="header-content container">
                        <h1 className="header-title">
                            <span className="up">{t('Hi')}</span>
                            <span className="down">{t('my_name')}</span>
                        </h1>
                        <p className="header-subtitle">{t('developer')}</p>
                        {/* <button class="btn btn-primary">Visit My Works</button> */}
                    </div>
                </header>{/* end of page header */}
    </>
  )
}

export default Header