import React, { useState } from 'react'
import Secondary from '../Secondary';
import { useTranslation, Trans } from 'react-i18next';

function BlogSec() {
  const { i18n, t } = useTranslation();



  const [data, setData] = useState([
    { name: 'amir', family: 'khesali', age: '26' },
    { name: 'ali', family: 'amiri', age: '26' },
    { name: 'rahman', family: 'rezaee', age: '26' },
    { name: 'rahim', family: 'mohamadi', age: '26' },
  ]);



  return (
    <>

      <Secondary title={t('Blog')}>
        {data.map(index => (
          <div className='blog-boxes-main' >
            <div className="blog-card">
              <div className="blog-card-header">
                <img src="assets/imgs/img-1.jpg" className="blog-card-img" alt="Download free bootstrap 4 landing page, free boootstrap 4 templates, Download free bootstrap 4.1 landing page, free boootstrap 4.1.1 templates, meyawo Landing page" />
              </div>
              <div className="blog-card-body">
                <h5 className="blog-card-title">Consectetur adipisicing elit</h5>
                <p className="blog-card-caption">
                  <a href="#">By: Admin</a>
                  <a href="#"><i className="ti-heart text-danger" /> 234</a>
                  <a href="#"><i className="ti-comment" /> 123</a>
                </p>
                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Amet nesciunt qui sit velit
                  delectus voluptates, repellat ipsum culpa id deleniti. Rerum debitis facilis accusantium
                  neque numquam mollitia modi quasi distinctio.</p>
                <p><b>Necessitatibus nihil impedit! Ex nisi eveniet, dolor aliquid consequuntur repudiandae.</b>
                </p>
                <p>Magnam in repellat enim harum omnis aperiam! Explicabo illo, commodi, dolor blanditiis
                  cupiditate harum nisi vero accusamus laudantium voluptatibus dolores quae obcaecati.</p>
                <a href="#" className="blog-card-link">Read more <i className="ti-angle-double-right" /></a>
              </div>
            </div>
          </div>
        ))}
      </Secondary>


    </>


  )
}

export default BlogSec