import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

function About() {
    const { i18n, t } = useTranslation()
    return (
        <>
            {/* about section */}
            <section className="section" id="about">
                {/* container */}
                <div className="container text-center">
                    {/* about wrapper */}
                    <div className="about">
                        <div className="about-img-holder">
                            <img src="assets/imgs/1.jpg" className="about-img" style={{ clipPath: 'polygon(25% 0%, 100% 0%, 75% 100%, 0% 100%)' }} alt="Download free bootstrap 4 landing page, free boootstrap 4 templates, Download free bootstrap 4.1 landing page, free boootstrap 4.1.1 templates, meyawo Landing page" />
                        </div>
                        <div className="about-caption">
                            <p className="section-subtitle">{t('knowing')}</p>
                            <h2 className="section-title mb-3">{t('about_me')}</h2>
                            <p>
                                {t('short_see')}
                                <br />
                                {t('short_see2')}
                                <br />
                                {t('short_see3')}
                            </p>
                            <Link to='/resume' >
                                <button className="btn-rounded btn btn-outline-primary mt-4">{t('download_cv')}</button></Link>
                        </div>
                    </div>{/* end of about wrapper */}
                </div>{/* end of container */}
            </section> {/* end of about section */}
        </>
    )
}

export default About