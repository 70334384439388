import React from 'react'
import { useTranslation } from 'react-i18next';
import { BsArrowLeftShort, BsArrowRightShort } from "react-icons/bs";
import { Link } from 'react-router-dom';

function Portfolio() {

    const { i18n, t } = useTranslation()

    return (
        <>
            {/* portfolio section */}
            <section className="section" id="portfolio">
                <div className="container text-center">
                    <p className="section-subtitle">{t('What_Project_I_Do')}</p>
                    <h6 className="section-title mb-6">{t('Projects')}</h6>
                    {/* row */}
                    <div className="row justify-content-around">
                        <div className="col-md-4">
                            <span href="#" className="portfolio-card">
                                <img src="assets/imgs/folio-1.jpg" className="portfolio-card-img" alt="Download free bootstrap 4 landing page, free boootstrap 4 templates, Download free bootstrap 4.1 landing page, free boootstrap 4.1.1 templates, meyawo Landing page" />
                                <span className="portfolio-card-overlay">
                                    <span className="portfolio-card-caption">
                                        <h4>FRONT END</h4>
                                        <Link to={"/front"} >
                                            <button className='portfolio-pic-butt1'>
                                                <span>Watch</span>
                                                <BsArrowRightShort color='' size={20} className='' />
                                            </button>
                                        </Link>
                                    </span>
                                </span>
                            </span>
                        </div>
                        <div className="col-md-4">
                            <span href="#" className="portfolio-card">
                                <img className="portfolio-card-img" src="assets/imgs/folio-3.jpg" alt="Download free bootstrap 4 landing page, free boootstrap 4 templates, Download free bootstrap 4.1 landing page, free boootstrap 4.1.1 templates, meyawo Landing page" />
                                <span className="portfolio-card-overlay">
                                    <span className="portfolio-card-caption">
                                        <h4>BACK END</h4>
                                        <Link to={"/front"} >
                                            <button className='portfolio-pic-butt2'>
                                                <BsArrowLeftShort color='' size={20} className='' />
                                                <span>Watch</span>
                                            </button>
                                        </Link>
                                    </span>
                                </span>
                            </span>
                        </div>
                    </div>{/* end of row */}
                </div>{/* end of container */}
            </section> {/* end of portfolio section */}
        </>
    )
}

export default Portfolio