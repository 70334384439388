import React from 'react' ;
import '../../../Styles/Styles.css'

function Footer() {
    return (
        <>
            {/* footer */}
            <div className="container">
                <footer className="footer">
                    <div className="social-links text-right m-auto ml-sm-auto">
                        <a href="https://t.me/s/amirrezakhs1" className="link contact-footer"><i className="ti-facebook" /></a>
                        <a href="#" className="link contact-footer"><i className="ti-google" /></a>
                        <a href="#" className="link contact-footer"><i className="ti-instagram" /></a>
                        <a href="#" className="link contact-footer"><i className="ti-linkedin" /></a>
                    </div>
                </footer>
            </div> {/* end of page footer */}
        </>
    )
}

export default Footer